@charset "UTF-8";
#tmenu-wrapper {
  background: #E72886;
  margin: 10px 0; }

#tmenu {
  margin: 0 auto;
  display: block; }
  #tmenu ul, #tmenu li {
    list-style: none;
    padding: 0;
    margin: 0; }
  #tmenu > ul {
    display: flex;
    position: relative;
    margin-left: -15px;
    margin-right: -15px; }
  #tmenu a {
    transition: .4s; }
    #tmenu a:hover {
      transition: .3s; }
  #tmenu > ul > li {
    flex: 1 1 auto; }
    #tmenu > ul > li.home {
      flex: 0 0 auto; }
      #tmenu > ul > li.home a {
        font-size: 2.8rem;
        padding: 10px 15px 11px; }
    #tmenu > ul > li > a {
      text-align: center;
      color: #fff;
      padding: 14px 15px;
      font-size: 2.2rem;
      line-height: 1;
      text-transform: uppercase; }
    #tmenu > ul > li._current > a,
    #tmenu > ul > li._open > a,
    #tmenu > ul > li > a:hover {
      background: #bd1568; }
    #tmenu > ul > li ul, #tmenu > ul > li li, #tmenu > ul > li a {
      display: block; }
    #tmenu > ul > li > ul {
      display: none;
      background: #fff;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: 5rem;
      z-index: 99;
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 7px 15px; }
      #tmenu > ul > li > ul a {
        color: #888; }
        #tmenu > ul > li > ul a:hover {
          color: #E72886; }
      #tmenu > ul > li > ul > li {
        float: left;
        width: 25%;
        margin-bottom: 15px; }
        #tmenu > ul > li > ul > li > a {
          font-size: 2rem;
          text-transform: uppercase; }
        #tmenu > ul > li > ul > li > ul {
          padding-bottom: 10px; }
          #tmenu > ul > li > ul > li > ul > li a {
            position: relative; }
            #tmenu > ul > li > ul > li > ul > li a:before {
              position: absolute;
              left: 2px;
              top: 5px;
              color: #E72886;
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: ""; }
          #tmenu > ul > li > ul > li > ul > li > a {
            font-size: 1.6rem;
            padding: 3px  0 3px 15px; }
          #tmenu > ul > li > ul > li > ul > li > ul {
            padding-top: 0px;
            padding-bottom: 10px; }
            #tmenu > ul > li > ul > li > ul > li > ul > li > a {
              padding: 2px 0 2px 20px;
              font-size: 14px; }
              #tmenu > ul > li > ul > li > ul > li > ul > li > a:before {
                left: 9px;
                top: 3px; }
    #tmenu > ul > li._open:not(.home) > ul {
      display: block; }

@media (max-width: 991px) {
  #tmenu-wrapper {
    display: none; } }
